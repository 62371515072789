.my-custom-input {
    border-radius: 5px !important;
    height: 40px !important;
    --styled-select-placeholder__padding: 3px 10px 0 10px;
    --styled-select-placeholder__color: #979797;
    --styled-select-placeholder__font-size: 14px;
    font-family: "SF Pro Text" !important;
    --styled-select-placeholder__font-family: "SF Pro Text" !important;
    --styled-select-menu__border-radius: 5px;
    --styled-select-value__color: #171717 !important;
    --styled-select-value-label__font-family: "SF Pro Text" !important;
    color: #171717 !important;
    --styled-select__color: #171717 !important;
    --styled-select-menu__border-radius: 5px;
    border: solid 1px rgba(0, 40, 100, 0.12) !important;
}

.my-custom-input.error {
    border: solid 1px #cd201f !important;
}
.my-custom-menu {
    border-radius: 12px !important;
    --styled-select-menu__border-radius: 12px !important;
    border: none !important;
    --styled-select-option__background-color: #fff;
    --styled-select-option__background-color--focused: #fff;
    --styled-select-option__color: #000;
    --styled-select-option__color--focused: #000;
    --styled-select-option__color--selected: #000;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}
.custom-option {
    border-bottom: 1px solid #e5e9ef;
    --styled-select-option__font-family: "SF Pro Text" !important;
    --styled-select-option__background-color--selected: #f6f6f6;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.eMbsGR {
    font-family: "SF Pro Text" !important;
}
.korfBa {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.my-custom-arrow {
    border-bottom-width: 2px !important;
    border-right-width: 2px !important;
}
.filter {
    height: 32px !important;
    --styled-select-placeholder__padding: 2px 8px 0 8px;
    --styled-select-placeholder__color: #171717;
    --styled-select-placeholder__font-size: 13px;
}
.filter-option {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 13px;
}
.iEjkRj .hftDWz {
    color: #000;
}
.iEjkRj .bzBWvN {
    border-right-color: #000;
    border-bottom-color: #000;
}

@media (max-width: 711px) {
    .my-custom-input {
        --styled-select-placeholder__font-size: 12px;
    }
    .custom-option {
        font-size: 12px;
    }
} 
