/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.c3 path,
.c3 line {
  fill: none;
  stroke: rgba(0, 40, 100, 0.12);
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: px2rem(12px);
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #f0f0f0;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid,
.c3-ygrid {
  stroke: #e6e6e6;
  stroke-dasharray: 2 4;
}

/*-- Text on Chart --*/
.c3-text {
  font-size: 12px;
}

.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 2px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 2px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 1.5px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item text {
  fill: #545454;
  font-size: 14px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  fill: transparent;
  stroke: lightgray;
  stroke-width: 0;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 11px;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  background: #212529 !important;
  white-space: nowrap;
}

.c3-tooltip th {
  padding: 6px 6px;
  text-align: left;
}

.c3-tooltip td {
  padding: 4px 6px;
  font-weight: 400;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  vertical-align: baseline;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.1;
}

.c3-target-filled .c3-area {
  opacity: 1 !important;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

.c3-axis {
  fill: #9aa0ac;
}
.h4 {
  color: #000 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-40 {
  margin-top: 24px;
}
.pt-40 {
  padding-top: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
input#expiryDate {
  margin-left: 12px !important;
}
input#cvc {
  margin-left: 12px;
}
.what-this.dropdown {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
}
.what-this.dropdown .dropdown-toggle {
  color: #979797;
  font-size: 11px;
  line-height: 19px;
  text-decoration-line: underline;
}
.what-this.dropdown.show .dropdown-toggle {
  color: #000000;
  font-size: 11px;
  line-height: 19px;
  text-decoration-line: underline;
}
.what-this.dropdown .dropdown-toggle::after {
  display: none;
}
.what-this.dropdown .dropdown-menu.show {
  width: 100%;
  max-width: 100%;
  inset: unset !important;
}
.what-this.dropdown .dropdown-menu .item {
  padding: 12px 17px !important;
}
.what-this.dropdown .dropdown-menu h3 {
  color: #000000;
  font-size: 14px;
  line-height: 24px;
}
.what-this.dropdown .dropdown-menu p {
  color: #979797;
  font-size: 14px;
  line-height: 24px;
}
.right-10 {
  right: 10px;
}
.p-100 {
  padding: 93px 50px 90px 50px;
}
.plan-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.plan-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  width: calc((100% - 100px) / 3);
  height: 333px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 40px;
  justify-content: space-between;
  /* margin-right: 15px; */
  min-width: 200px;
  margin-top: 20px;
}
.plan-text1 {
  color: #000;
  font-size: 20px;
  font-family: "SF Pro Text";
  font-weight: bold;
}
.plan-text2 {
  color: #74797e;
  font-size: 10px;
  font-family: "SF Pro Text";
}
.plan-text3 {
  color: #000;
  font-size: 14px;
  font-family: "SF Pro Text";
}
.plan-card.current {
  opacity: .5;
}
.plan-title {
  font-size: 40px;
  color: #000;
  font-family: "SF Pro Text";
  font-weight: 500;
}
.plan-sub-title {
  font-size: 14px;
  color: #4d4d4d;
  font-family: "SF Pro Text";
}
.btn.btn-barlows.white-btn {
  background-color: white;
  color: #000;
}
@media (max-width: 1024px) {
  .mb-20 {
    margin-bottom: 10px !important;
  }
  .mt-20 {
    margin-top: 10px !important;
  }
  .mb-40 {
    margin-bottom: 30px;
  }
  .billing-cards .col-lg-6 {
    flex: auto !important;
    max-width: 100% !important;
  }
  .p-100 {
    padding: 70px 30px 70px 30px;
  }
  .plan-card {
    width: 300px;
  }
}
@media (max-width: 711px) {
  .mb-20 {
    margin-bottom: 0px !important;
  }
  .mt-20 {
    margin-top: 0px !important;
  }
  .mb-40 {
    margin-bottom: 20px;
  }
  .mt-40 {
    margin-top: 20px !important;
  }
  .pt-40 {
    padding-top: 20px !important;
  }
  .pb-40 {
    padding-bottom: 20px !important;
  }
  .h4 {
    font-size: 14px !important;
  }
  .billing-cards .col-lg-4 .card,
  .billing-cards .col-lg-6 .card {
    height: 127px !important;
  }
  #basic-navbar-nav {
    z-index: 1000;
  }
  .what-this.dropdown .dropdown-menu h3 {
    font-size: 12px;
  }
  .what-this.dropdown .dropdown-menu p {
    font-size: 12px;
  }
  .plan-title {
    font-size: 34px;
  }
  .plan-sub-title {
    font-size: 12px;
  }
  .plan-card {
    margin-right: 0;
  }
}
@media (min-width: 711px) {
  .billing-cards .col-lg-4 {
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
  }
}
