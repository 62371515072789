.GoogleMapContainer {
  height: 100%;
}

.GoogleMapContainer.blackAndWhite {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

div.gmnoprint {
  padding-top: 65px;
  padding-left: 10px;
}
.gmnoprint .gm-control-active > img {
  display: none !important;
}
.gmnoprint > div {
  box-shadow: none !important;
  background-color: unset !important;
}
.gmnoprint > div > div > div {
  display: none !important;
}
.gmnoprint .gm-control-active {
  height: 32px !important;
  width: 32px !important;
  border-radius: 5px !important;
}
.gmnoprint .gm-control-active:first-child {
  box-sizing: border-box !important;
  border: 1px solid #e5e9ef !important;
  margin-bottom: 10px !important;
  background-image: url("/images/zoomind.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
}
.gmnoprint .gm-control-active {
  box-sizing: border-box !important;
  border: 1px solid #e5e9ef !important;
  background-image: url("/images/zoomoutd.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
}

.map-zoomin:focus {
  outline: none;
}
.map-zoomout:focus {
  outline: none;
}

.map-title {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  color: #000;
  font-family: "SF Pro Text";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 20px;
  margin-bottom: 20px;
}

.GoogleMapContainer > div {
  border-radius: 10px;
}

@media (max-width: 711px) {
  .gmnoprint .gm-control-active {
    height: 25.6px !important;
    width: 25.6px !important;
    border-radius: 4px !important;
  }
  .gmnoprint .gm-control-active:first-child {
    background-image: url("/images/zoomin.svg") !important;
  }
  .gmnoprint .gm-control-active {
    background-image: url("/images/zoomout.svg") !important;
  }
}
